<template>
  <div class="container-fluid p-0" :style="'height: ' + containerHeight + 'px'">
    <CRow style="padding-top: 20px; padding-bottom: 20px" >
		<CCol cols="2" xl="2" style="display:flex; gap: 12px;">
			<CButton @click="$router.push('/dashboard')" variant="outline" color="dark" class="mt-3">Volver</CButton>
				<!-- select cuadrante lista -->
	<div style="width: 250px;" v-if="$store.state.userData.role.user_role_id == 4 && !$store.state.mainCamera">
		<p class="mb-0">Seleccione Cuadrante:</p>
		<v-select style="width: 250px;" :options="cuadrantes" label="nombre" :reduce="data => data.id" v-model="cuadrante_id" :clearable="false">
			<template slot="option" slot-scope="option">
				<div style="height: 50px;">
        		<p style="font-weight: 500; font-size: 16px;" class="pb-0 mb-0">{{ option.nombre }}</p>
        		<span v-for="comuna in option.comuna_id" :key="comuna.id" style="font-size: 12px;">{{ comuna.comuna }} - </span>
				</div>
    		</template>
		</v-select>
	</div>
		</CCol>
		<CCol col="8" xl="8">
			<h4 class="text-center mb-0">Cámaras Activas</h4>
			<h6 class="text-center text-muted">Se muestran las cámaras que han sido compartidas con el Panel SOSclick.</h6>
		</CCol>
		<CCol col="2" xl="2" class="text-right">
				<CButtonGroup v-show="cameras.length" class="mt-3 mx-auto">
					<CButton class="mx-0" color="dark" variant="outline"
						@click="prev">
						<font-awesome-icon icon="chevron-left" style="color: #424242;font-size: 14px" />
					</CButton>
					<CButton :disabled="disableNext" class="mx-0" color="dark" variant="outline"
						@click="next">
						<font-awesome-icon icon="chevron-right" style="color: #424242;font-size: 14px" />
					</CButton>
			<CButton
					v-c-tooltip="'Vista Completa'"
					class="rounded-circle grid-button ml-2"
					variant="outline"
					@click="activarPantallaCompleta"
					style="display: flex; justify-content: center; align-items: center;"
				>
					<img style="width: 20px; height: 20px;" src="../../../assets/icons//svg/arrow-expand-all.svg" alt="">
				</CButton>
				</CButtonGroup>
			</CCol>
    </CRow>

		<div :style="'overflow-y: scroll;height: ' + (containerHeight - 100) + 'px'">
			<div class="full-camera-container"  ref="pantallaCompleta">
	<CButton
	v-if="full_screen"
			v-c-tooltip="'Salir Vista Completa'"
			class="rounded-circle grid-button"
			style="position: absolute; top: 4px; right: 4px;z-index: 124;display: flex; justify-content: center; align-items: center;"
			variant="outline"
			@click="salirPantallaCompleta"
		>
		<img style="width: 20px; height: 20px;" src="../../../assets/icons//svg/arrow-collapse-all.svg" alt="">
		</CButton>
				<div class="main-camera-container" v-show="$store.state.mainCamera" ref="mainCameraContainer">
				</div>
		
				<div v-if="cameras.length" class="camera-grid-container"
					:class="$store.state.mainCamera ? 'column':''"
					:style="$store.state.mainCamera && !full_screen ? 'height: ' + (containerHeight - 100) + 'px':''">

					<div class="single-camera-container" v-for="(camera, index) in cameras" :key="index"
						v-show="camera.deviceId != $store.state.mainCamera"
						:ref="camera.deviceId + '_container'">
						<CameraView
							:ref="camera.deviceId"
							:cameraData="camera"
							:showCameraViewComponent="$store.state.grillaMonitoreo"
							:socket="socket"
							:cuadrante_id="cuadrante_id"
						/>
					</div>

					</div>
			
				<p style="margin: 0 auto" v-if="!cameras.length && !no_cameras">Cargando Cámaras...</p>

				<p style="margin: 0 auto" v-if="!cameras.length && no_cameras">No hay cámaras disponibles</p>
			</div>
		</div>
  </div>
</template>

<script>
import CameraView from "./CameraView";
import io from 'socket.io-client';
import { cameraList } from '../../../api/cameras'
import Pagination from '../../../helpers/Pagination.vue'
import { getCuadrantes } from '../../../api/cuadrantes'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { getDetails } from '../../../api/user'

export default {
  name: "TuyaCamerasGrid",
  components: {
    CameraView,
    Pagination,
	'v-select': vSelect
  },
	watch: {
    '$store.state.tuyaCameras': function(value) {
      this.cameras = [...value]
    },
	cuadrante_id(value) {

		this.cameras.forEach(camera => {
			this.socket.emit("removeCameraConnection", camera?.deviceId, (response) => {
				console.log('removeCameraConnection', response, camera?.deviceId)
			})
		});
		this.exclude_cameras = [];
		const data = {cuadranteId: value}
		if (this.$store.state.userData.role.user_role_id == 4) {
			data.sa = true
		}
		
		this.socket.emit("getCamerasByCuadrante", data, (res) => {

		this.exclude_cameras = res.cameras;
		if (this.$route.query.user_id) {
			this.getDetails();
		} else {
			this.getCameraList();
		}
		});
    },
	'$store.state.updateCuadrante': function(value) {
		this.getCameraList();
	}
	},
  data() {
    return {
      headerHeight: 56,
      containerHeight: 700,
      requestedVideoInfo: false,
      cameras: [],
      socket: null,
      socketUrl: 'https://devapis.anubys.cl:3000',
      loading: false,
      mainVideo: null,
			previousMainCamera: null,
			storeSubscription: null,
      pagination: {
        page: 1,
        limit: 15,
        total: null,
      },
		disableNext: true,
      changeRoute: true,
	  cuadrantes: [],
	  cuadrante_id: null,
	  user_id: null,
	  loading_btn: false,
	  no_cameras: false,
	  exclude_cameras: [],
	  full_screen: false,
    };
  },
  mounted() {
    this.containerHeight = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight

	this.socket = io(this.socketUrl, {
		transports: ["websocket"],
		autoConnect: true,
		reconnect: true,
		forceNew: true,
	});

	if (this.$store.state.userData.role.user_role_id == 4) {
		if (this.$route.query.cuadrante_id) {
			this.cuadrante_id = +this.$route.query.cuadrante_id
		}
		this.getCuadrantes();
	} else {
		this.cuadrante_id = this.$store.state.userData?.info?.cuadrante_id
	}

		this.storeSubscription = this.$store.subscribe((mutation, state) => {
			if(mutation.type == 'setMainCamera'){
				this.setMainCamera(mutation.payload, this.previousMainCamera)
				this.previousMainCamera = mutation.payload
			}
		})

		document.addEventListener("fullscreenchange", this.handleFullScreenChange);
		document.addEventListener("webkitfullscreenchange", this.handleFullScreenChange);
  },
  beforeRouteLeave(to, from, next) {
	this.$store.commit('setMainCamera', null)
    this.socket?.disconnect();
		this.storeSubscription && this.storeSubscription()
    next();
  },
  beforeDestroy() {
    // Limpiar los eventos cuando el componente se destruye
    document.removeEventListener("fullscreenchange", this.handleFullScreenChange);
    document.removeEventListener("webkitfullscreenchange", this.handleFullScreenChange);
  },
  methods: {
    setMainCamera(camera_id, prev_camera_id){
		if(prev_camera_id){
				this.toGridCamera(prev_camera_id)
			}
			if(camera_id){
				const toMain = this.$refs[camera_id][0].$el
				const mainCameraContainer = this.$refs.mainCameraContainer
				mainCameraContainer.appendChild(toMain);
				const camera = this.cameras.find(c => c.deviceId === camera_id);
				const obj = {cuadrante_id: camera.cuadrante_id || null,  user_id: camera.user_id }
				this.$store.commit('setDataCuadranteCamera', obj)
			}
		},
		toGridCamera(camera_id){
			const toGrid = this.$refs[camera_id][0].$el
			const gridCameraContainer = this.$refs[camera_id + '_container'][0]
			gridCameraContainer.appendChild(toGrid)
			const camera = this.cameras.find(c => c.deviceId === camera_id);
				const obj = {cuadrante_id: camera.cuadrante_id || null,  user_id: camera.user_id }
				this.$store.commit('setDataCuadranteCamera', obj)
		},
    next() {
      this.pagination.page += 1
      this.getCameraList()
    },
    prev() {
      this.pagination.page -= 1
      this.getCameraList()
    },
    async getCameraList() {
      this.cameras = []
	  this.no_cameras = false
	  const keep_ids = this.$route.query.keep_id ? [+this.$route.query.keep_id] : []
      const data = {limit: this.pagination.limit, page:this.pagination.page, keep_ids, exclude_cameras: this.exclude_cameras}
	  if (this.cuadrante_id) {
		data.cuadrante_id = this.cuadrante_id
	  }

      const camaras = await cameraList(data)
      
      if (camaras.connections.length) {
        this.$store.commit('setTuyaCameras', camaras.connections)
        this.disableNext = false
      } else {
        this.disableNext = true
		this.no_cameras = true
      }
    },
    getCuadrantes(){
      const data = {
        page: 1,
        limit: 9999999
      }
      const self = this
      getCuadrantes(data).then(function(resp){
			self.cuadrantes = resp.data
			if (!self.cuadrante_id) {
				self.cuadrante_id = self.$store.state.userData?.info?.cuadrante_id
			}
        })
        .catch(function(error){
          console.log(error)
        })
    },
	activarPantallaCompleta() {
		this.$store.commit('setMainCamera', null)
      const elem = this.$refs.pantallaCompleta;
      // Verificar la API y activar pantalla completa
	  this.full_screen = true
	  this.pagination.limit = 25;
	  this.getCameraList()
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { // Firefox
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { // Chrome, Safari y Opera
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // Internet Explorer/Edge
        elem.msRequestFullscreen();
      }
    },
	salirPantallaCompleta() {
	  if (this.full_screen) {
		
		  this.full_screen = false
		  if (document.exitFullscreen) {
			  document.exitFullscreen(); // Para navegadores modernos
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
	} else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      } else {
		  console.log('El navegador no soporta salir de pantalla completa');
		}
	}
    },
    handleFullScreenChange() {
      // Verificar el estado de pantalla completa
      this.isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;
	  if (!this.isFullScreen) {
		this.$store.commit('setMainCamera', null)
		this.pagination.limit = 15;
		this.getCameraList()
		this.full_screen = false
	  }
    },
	getDetails() {
		getDetails(+this.$route.query.user_id).then(res => {
			if (res.connections.length) {
				this.$store.commit('setTuyaCameras', res.connections)
				this.disableNext = false
			} else {
				this.disableNext = true
				this.no_cameras = true
			}
        })
	}
  },
}
</script>
<style scoped>

  .grid-default {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 100%;
  }

  @media (min-width: 1700px) {
    .grid-default {
    height: 70vh;
    }
  }

	.full-camera-container{
/*     max-width: 1200px;
    margin: 0 auto; */
		display: flex;
		flex: 1;
	}
/*   @media screen and (min-width: 2000px) {
    .full-camera-container {
        max-width: 2000px;
    }
} */

	.main-camera-container{
		flex: 1;
		margin: 2px;
	}
	.camera-grid-container{
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		height: fit-content;
	}
	.camera-grid-container.column{
		flex-direction: column;
		flex-wrap: inherit;
		width: 20%;
		max-width: 300px;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100vh;
	}
	.single-camera-container{
		flex: 0 0 calc(20% - 4px);
		margin: 2px;
		aspect-ratio: 16/9;
		height: fit-content
	}


	.fullscreen-div {
  width: 100%;
  height: 200px;
  background-color: lightblue;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid #ccc;
}
.grid-button {
		background-color: rgba(0,0,0,0.4);
		aspect-ratio: 1;
		line-height: 1;
		width: 40px;
		height: 40px;
	}


</style>