<template>
          <GmapInfoWindow v-if="user_id" style="overflow: visible">
        <CRow style="width: 550px" v-if="!currentMarker">
		    <CCol col="12" xl="12" class="text-center mt-2">
	        <CSpinner
	          style="width:20px;height:20px;"
	          color="primary"
	        />
	        <p>Cargando información del usuario</p>
		    </CCol>
      </CRow>

            <div v-else class="FixedCard" style="position: relative">
              <p
              @click="close"
                style="
                  position: absolute;
                  top: 8px;
                  right: 16px;
                  font-size: 24px;
                  font-weight: 500;
                  cursor: pointer;
                "
              >
                x
              </p>
              <div class="FixedCard__Header">
                <img
                  class="FixedCard__Header__Img"
                  :src="currentMarker?.avatarImg != null
                      ? currentMarker?.avatarImg
                      : require('../../../assets/icons/user.svg')
                  "
                  alt="avatar"
                />
                <div class="FixedCard__Header__Data">
                  <h4 class="FixedCard__Header__Data__Item text-primary">
                    {{ currentMarker?.name }}
                  </h4>
                    <span style="font-size: 12px;" v-if="markerType == 'fixedDevice'">Actualizado: {{ userInfo.location.updated_at | filterDate }}</span>
                  <p v-if="markerType == 'fixedDevice'" class="FixedCard__Header__Data__Item">
                    <span>{{ userInfo.device_brand }}</span> - <span>{{ userInfo.device_model }}</span>
                  </p>
                  <p v-if="markerType == 'tracking'" class="FixedCard__Header__Data__Item">
                    {{ date | filterDate }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ currentMarker?.email }}
                  </p>
                  <p class="FixedCard__Header__Data__Item">
                    {{ currentMarker?.info?.telefono }}
                  </p>
                </div>
              </div>
              <div class="custom-title-info-window">
            <h6 style="font-size: 18px;" class="text-primary m-0">Acciones</h6>
            <hr/>
          </div>
              <div v-if="type == 'multipleDevices'"  style="display:flex; align-items:center; gap: 8px;">

                <div v-if="devices.length"> 
              <label>Seleccione Dispositivo:</label>
              <CSelect
                v-if="type == 'multipleDevices'"
                style="margin-top: 8px; width: 200px;"
                :options="devices"
                v-model="selectedDevice"
                />
              </div>
              <small v-else>No hay dispositivos disponibles</small>
                </div>
        <CRow>
<!--         <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall(true, true, true)"
          >
            <CIcon name="silentMonitor" class="icons-svg outline"/> Monitoreo Silencioso
          </CButton>
        </CCol> -->
        <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="!cameras_count"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="viewCameras()"
          >
            <CIcon name="silentMonitor" class="icons-svg outline"/>({{ cameras_count }}) {{cameras_count ? 'Ver Cámaras' : 'Sin Cámaras'}}
          </CButton>
        </CCol>
          <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="!devices.length"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="startVideoCall()">
            <CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
          </CButton>
        </CCol>
                  <CCol col="4" xl="4" class="p-1">
          <CButtonGroup v-show="tracking">
            <CButton              
              color="primary"
              class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="startSoloTracking()"
            >
              <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/> Visualizar Ubicación
            </CButton>
            <CButton
              :disabled="deactivatingTracking"
              v-c-tooltip="'Desactivar Tracking'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="deactivateTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <div v-if="!deactivatingTracking">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.2rem!important;height: 1.2rem!important;"/>
                <font-awesome-icon icon="slash" style="font-size: 12px;position: absolute;left: 0;top: 0;bottom: 0;right: 0;margin: auto;" />
              </div>
              <div v-else>
                <CSpinner style="width:20px;height:20px;" color="primary" />
              </div>
            </CButton>
          </CButtonGroup>
          <CButtonGroup v-show="!tracking">
            <CButton
              :disabled="activatingTracking"
              color="primary"
              class="w-100 custom-button-info-content d-flex flex-column justify-content-around align-items-center py-2"
              variant="outline"
              style="flex: 1;"
              @click="activateTracking()"
            >
              <div v-if="!activatingTracking" class="w-100 d-flex justify-content-around align-items-center">
                <CIcon name="location" size="xl" class="icons-svg" style="width: 1.7rem!important;height: 1.7rem!important;"/>
                <p class="m-0">Activar Tracking</p>
              </div>
              <div v-else class="w-100 d-flex justify-content-around align-items-center">
                <CSpinner style="width:20px;height:20px;" color="primary" />
                <p class="m-0">Activando Tracking</p>
              </div>
              <p class="m-0" style="font-size: 10px;">{{trackingActivationMessage}}</p>
            </CButton>
            <CButton
              v-c-tooltip="'Consultar histórico'"
              size="sm"
              class="custom-button-info-content"
              variant="outline"
              color="primary"
              @click="startSoloTracking()"
              style="width: auto;flex: 0;text-align: center;margin: 0;padding: 5px;">
              <font-awesome-icon icon="history" style="font-size: 20px;" />
            </CButton>
          </CButtonGroup>
        </CCol>
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-3"
            :class="this.showNoti ? 'text-white':''"
            :variant="this.showNoti ? '':'outline'"
            style="flex: 1;"
            @click="() => this.showNoti = !this.showNoti"
          >
            <CIcon :name="this.showNoti ? 'cilX':'notification'" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> {{this.showNoti ? 'Cancelar':'Crear'}} Notificación
          </CButton>
		    </CCol>
<!--           <CCol col="4" xl="4" class="px-1">
          <CButton
            :disabled="$store.state.toCallData ? true:false"
            color="primary"
            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-2"
            variant="outline"
            style="flex: 1;"
            @click="call()"
          >
            <CIcon name="call" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> Llamar por Teléfono
          </CButton>
        </CCol> -->

<!--         <CDropdown
        v-if="type == 'address'"
        placement="right"
        :disabled="!currentMarker.dispositivos.length"
    color="primary"
    toggler-text="Video LLamada" 
    class="m-2"
  >
  <div style="height: 200px; overflow-y: scroll; max-width: 500px;">
    <CDropdownItem @click="startVideoCall(item.device_id)" v-for="(item, index) in currentMarker.dispositivos" :key="'routes' + index">{{item.device_name}}</CDropdownItem>
  </div>
  </CDropdown> -->
        </CRow>
              <CRow class="mt-2 pt-2" style="border-top: 1px solid #f2f2f2" v-show="showNoti">
        <CCol col="12" xl="12" class="text-center">
          <CInput
            placeholder="Título"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionTitle'
          />
          <CInput
            placeholder="Descripción"
            addInputClasses="input-notification-info-window-sos"
            v-model='notificacionDescripcion'
          />
          <CAlert v-for="(toast,index) in toasts" :key="index" :color="toast.color" closeButton :show.sync="currentAlertCounter">
            {{toast.message}}
          </CAlert>
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
        </CCol>        
        <CCol col="4" xl="4" class="px-1">
          <CButton
            color="success"
            class="w-100 custom-button-info-content success d-flex justify-content-around align-items-center py-2"
            style="flex: 1;"
            @click="() => this.sendNotification()"
          >
            <CIcon name="send" size="xl" class="icons-svg outline" style="width: 1.5rem!important;height: 1.5rem!important;"/> Enviar Notificación
          </CButton>
        </CCol>        
      </CRow>
            </div>
          </GmapInfoWindow>
</template>

<script>
import { getDetails, sendCustomNotification } from '../../../api/user'
import {activateTrackingRemote} from '../../../api/tracking'
import * as firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'

export default {
    props: ['user_id', 'closeAddressInfo', 'type', 'device_id', 'date', 'markerType', 'userInfo'],
    data () {
        return {
            currentMarker: null,
            devices: [],
            selectedDevice: null,
            cameras_count: null
,            //notificación
            showNoti: false,
            notificacionTitle: '',
            notificacionDescripcion: '',
            toasts: [],
            currentAlertCounter: 4,


            //tracking
    	      tracking: false,
            trackingActivationMessage: '',
            activatingTracking: false,
            deactivatingTracking: false
          }
    },
    computed: {
      deviceDataTracking() {
        const deviceSelected = this.currentMarker.dispositivos.filter(device => device.device_id == this.selectedDevice)[0]
        const device = this.userInfo
        return this.type == 'multipleDevices' ? deviceSelected : device;
      }
    },
    methods: {
        getUserDetails() {
            getDetails(this.user_id).then(res => {
                this.currentMarker = res.user
                this.cameras_count = res.connections?.length
                this.devices = res?.user?.dispositivos.map(dispositivo => {
                  return {label: dispositivo?.device_name, value:dispositivo?.device_id}
                })
                this.selectedDevice = res?.user?.dispositivos[res?.user?.dispositivos.length - 1].id
            })
        },
        close() {
            this.closeAddressInfo()
        },
        async startVideoCall(fullView = true, hideVideo = false, silent = true){
        this.$store.commit("setVideoCallData", {
            status: true,
            alertID: null,
            geoUserAlert: null,
            fullView,
            deviceID: this.type == 'multipleDevices' ? this.selectedDevice : this.device_id,
            calledUserID: this.currentMarker.id,
            socketUrl: null,
            socketRoom: null,
            videocallID: null,
            hideVideo,
            silent
        })
  	},
    call(){
      const callData = {
        phoneToCall: this.currentMarker?.info?.telefono,
        name: this.currentMarker?.name
      }
      this.$store.commit("setCallData", callData)
    },
    sendNotification(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(this.notificacionTitle === '' || this.notificacionDescripcion === ''){
        self.toasts.push({
          title: 'Error',
          message: 'Por favor completa todos los campos antes de enviar la notificación.',
          color: 'warning'
        })
      }
      else{
        const deviceID =  this.type == 'multipleDevices' ? this.selectedDevice : this.device_id
        sendCustomNotification(deviceID, this.notificacionTitle, this.notificacionDescripcion)
          .then(function(resp){
            self.toasts.push({
              title: 'Notificación enviada',
              message: 'Se ha enviado la notificación al usuario.',
              color: 'success'
            })
            setTimeout(function() {
              self.showNoti = false
            }, 3000);
          })
          .catch(function(error){
            console.log(error)
            self.toasts.push({
              title: 'Error',
              message: 'Hubo un error al enviar la notificación, por favor inténtalo nuevamente.',
              color: 'danger'
            })
          })
      }
    },
    startSoloTracking(){
      /////???
      const obj = {
        correo: this.currentMarker.email,
        deviceData: this.deviceDataTracking,
        deviceID: this.deviceDataTracking.device_id,
        id_usuario: this.currentMarker.info.user_id,
        latitud: this.type == 'multipleDevices' ?  this.userInfo?.address_geocoding.lat : this.userInfo.location.latitude,
        longitud:this.type == 'multipleDevices' ? this.userInfo?.address_geocoding.lng : this.userInfo.location.longitude,
        nombre: this.currentMarker.name,
        reconnects: 0,
        userActivo: true,
        key: this.deviceDataTracking.device_id
      }

      	this.$store.commit("setSoloTrackingUser", obj)
  	},
    activateTracking(){
      const device_id = this.type == 'multipleDevices' ? this.selectedDevice : this.device_id
      const self = this
      const data = {
        device_id,
        trackingStatus: 1
      }

      this.activatingTracking = true
      this.trackingActivationMessage = 'Activando el tracking en el dispositivo.'
      activateTrackingRemote(data)
        .then(function(resp){
          console.log("datae", resp)
          self.trackingActivationMessage = 'Esperando notificación de activación.'
          const path = 'SOSclick/tracking/' + self.currentMarker?.info?.cuadrante_id + '/' + self.currentMarker?.info?.user_id + '/' + device_id
          firebase.database(self.$store.state.trackingDB).ref(path).on("child_added", function(datos){
            if( datos.val() ){
              self.trackingActivationMessage = 'El tracking ha sido activado en el dispositivo, ahora podrás visualizar su posición en tiempo real.'
              setTimeout(function() {
                self.tracking = true
              }, 3000);
            }
            firebase.database(self.$store.state.trackingDB).ref(path).off("child_added")
            setTimeout(function() {
              self.activatingTracking = false
              self.trackingActivationMessage = ''
            }, 4000);
          })
        })
        .catch(function(error){
          console.log(error);
          self.trackingActivationMessage = 'Hubo un error de red al enviar la solicitud, por favor inténtalo nuevamente.'
          setTimeout(function() {
            self.activatingTracking = false
            self.trackingActivationMessage = ''
          }, 3000);
        })

    },
    deactivateTracking(){
      const self = this
      const device_id = this.type == 'multipleDevices' ? this.selectedDevice : this.device_id
      const data = {
        device_id,
        trackingStatus: 2
      }
      this.deactivatingTracking = true
      const path = 'SOSclick/tracking/' + self.currentMarker?.info?.cuadrante_id + '/' + self.currentMarker?.inofo?.user_id + '/' + device_id
      firebase.database(self.$store.state.trackingDB).ref(path).on("child_removed", function(datos) {
        firebase.database(self.$store.state.trackingDB).ref(path).off("child_removed")
        self.tracking = false
        self.deactivatingTracking = false
      })
      activateTrackingRemote(data)
        .then(function(resp){
          console.log("Enviado")
        })
        .catch(function(error){
          console.log(error)
          firebase.database(self.$store.state.trackingDB).ref(path).off("child_removed")
          self.deactivatingTracking = false
        })
    },
    viewCameras() {
      this.$router.push(`/monitors?cuadrante_id=${this.currentMarker?.info?.cuadrante_id}&user_id=${this.user_id}`)
    }
    },
    filters: {
      filterDate: function (date) {
        return moment(date).format("DD-MM-YYYY HH:mm");
      },
    },
    mounted() {
        console.log(this.userInfo);
        this.getUserDetails()
    },
    watch: {
      selectedDevice() {
        console.log(this.deviceDataTracking);
      }
    }
}
</script>

<style lang="scss" scoped>
.FixedCard {
  width: 648px;
  padding: 20px;
  &__Header {
    display: flex;
    align-items: center;

    gap: 32px;
    padding-bottom: 16px;
    &__Img {
      border: solid 2px #cccccc;
      width: 100px;
      border-radius: 10px;
      object-fit: cover;
      max-height: 100px;
      background-color: #e6ecff;
    }
    &__Data {
      &__Item {
        margin-bottom: 0;
        font-weight: 400;
      }
    }
  }
  &__Actions {
    margin-top: 16px;
    background-color: #F7F7F7;
    &__Title {
      display: flex;
      align-items: center;
    }
    &__Buttons {
      display: flex;
      &__Button {
        border: solid 1px #cccccc;
        border-radius: 8px;
        cursor: pointer;
        padding: 16px 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        &:hover {
          text-decoration: none;
          background-color: #e7e3e3;
        }
      }
    }
  }
}
.dropdown-menu .show {
    overflow: visible scroll;
    z-index: 99999;
    height: 200px;

}
</style>