<template>
  <div class="w-100 p-0 map-container-dashboard">
    <GmapMap ref="map" :center="$store.state.mapCenter.center" :zoom="$store.state.mapCenter.zoom"
      :options="{ streetViewControl: false, mapTypeControl: false, zoomControl: false, }"
      class="map-container" :style="'height: ' + containerHeight + 'px'" >
			<GmapHeatmap :data="heatData" :options="{maxIntensity: 120, dissipating: false}" />

      <!-- tracking -->
      <div v-if="$store.state.dashboardMarkers == 'tracking'">
        <div v-if="tracking_markers == 'record'">
      <div v-for="(item, index) in tracking" :key="'routes' + index">
      <GmapMarker
        :ref="'markerstart' + index"
        :position="item.route[item.route.length-1]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../assets/imgs/pin4.png'), scaledSize: { width: 28, height: 40 }}"
        :zIndex="3"
        @click="openTrackingInfo(item.id)"
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
      >
      <infoMarker v-if="$store.state.dashboardMarkers == 'tracking' && item.id == trackingMarkerInfo" :user_id="item.user_id" :closeAddressInfo="closeTrackingInfo" markerType="tracking" type="oneDevice" :device_id="item.device_id" :date="item?.updated_at"/>
      </GmapMarker>
      <GmapMarker
        :ref="'markerend' + index"
        :position="item.route[0]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../assets/imgs/pin5.png'), scaledSize: { width: 28, height: 40 }}"
        :zIndex="3"
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
        @click="openTrackingInfo(item.id)"
      />
<!--       <TrackingPopUp  :user_id="user_id" v-if="item" /> -->
      <GmapPolyline
        :key="'item' + index"
        :options="polylineOptions"
        :path="item.route"  
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
        @click="openTrackingInfo(item.id)"
        :ref="'polyline' + index"
      />
    </div>
  </div>


<!--     tracking real time -->
      <div v-else>
      <div v-for="(item, index) in rtTracking" :key="'routes' + index">
      <GmapMarker
        :visible="tracking_markers == 'real_time'"
        :ref="'markerstart' + index"
        :position="item.route[item.route.length-1]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../assets/imgs/pin4.png'), scaledSize: { width: 28, height: 40 }}"
        :zIndex="3"
        @click="openTrackingInfo(index)"
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
      >
      <infoMarker v-if="$store.state.dashboardMarkers == 'tracking' && trackingMarkerInfo == index" :user_id="item?.user?.user_id" :closeAddressInfo="closeTrackingInfo" markerType="tracking" type="oneDevice" :device_id="item.deviceID" :date="item?.position?.date"/>
      </GmapMarker>
      <GmapMarker
        :visible="tracking_markers == 'real_time'"
        :ref="'markerend' + index"
        :position="item.route[0]"
        :clickable="true"
        customInfo="Work"
        :icon="{url: require('../assets/imgs/pin5.png'), scaledSize: { width: 28, height: 40 }}"
        :zIndex="3"
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
        @click="openTrackingInfo(index)"
      />
<!--       <TrackingPopUp  :user_id="user_id" v-if="item" /> -->
      <GmapPolyline
        :key="'item' + index"
        :options="polylineOptions"
        :path="item.route"  
        @mouseover="changePolygonMouseOver($event, index)"
        @mouseout="changePolygonMouseOut(index)"
        @click="openTrackingInfo(item.id)"
        :ref="'polyline' + index"
      />
    </div>
</div>
          </div>
      <GmapMarker
        :key="'b'+index"
        :ref="'b'+index"
        v-for="(m, index) in clusterMarkers"
        :position="m.position"
        :clickable="true"
        :icon="{url: m.icon, scaledSize: { width: 32, height: 40 }}"
        @click="markerAction(m)"
        @mouseout="outCluster" @mouseover="inCluster"
      />
			<GmapMarker
				:key="index"
				:ref="index"
				:visible="showAlertas"
				v-for="(m, index) in alertas"
				customInfo="Work"
				:animation="1"
				:position="m.position"
				:clickable="true"
				:icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png'), scaledSize: { width: 32, height: 40 }}"
				@click="$store.state.autoVideoOnAlert ? startMonitorFromAlert(m.data):showModalAlert(m)"
			>
				<VideoCallRoomMultiple
					v-if="m.data.videocall && m.data.videocall.socket_url && m.data.videocall.socket_room && !$store.state.videocallSoloConnected && showAlertas"
					:videoCallData="m.data"
				/>
			</GmapMarker>
			<GmapMarker
				v-for="(m, index) in personalesFromCluster"
				:key="'p'+index"
				:ref="'p'+index"
				:visible="showPersonales && !checkIfMonitorInAlert(m.deviceID) && $store.state.dashboardMarkers == 'general'"
				:position="{lat: m.latitud, lng: m.longitud}"
				:clickable="true"
				customInfo="Work"
				:icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png'), scaledSize: { width: 32, height: 40 }, data: m, type: 1}"
				@click="requestUserInfo(m)"
			>
				<ContentInfo
					:closeInfo="closeContentInfo"
					:componentStatus="infoStatus.includes(m.deviceID)"
					:userInfo="m"
				/>
			</GmapMarker>
			<GmapMarker
				v-for="(m, index) in fixedDevices"
				:key="'z'+index"
				:ref="'z'+index"
				:visible="showFixedDevices && $store.state.dashboardMarkers == 'general' && !showClusters"
				:position="{lat: +m.location.lat, lng: +m.location.lng}"
				:clickable="true"
				customInfo="Work"
				:icon="{url: require('../assets/imgicons/fixedMarker.png'), scaledSize: { width: 60, height: 60 }, data: m, type: 3}"
				@click="openFixedDeviceInfo(m.device_id)"
			>
				<infoMarker
          v-if="$store.state.dashboardMarkers == 'general' && m.device_id == fixedDeviceId"
					:closeInfo="closeContentInfo"
					:userInfo="m"
          :user_id="m.user_id"
          :closeAddressInfo="closeFixedDeviceInfo"
          type="oneDevice"
          markerType="fixedDevice"
          :device_id="m.device_id"
				/>
			</GmapMarker>
      <GmapCluster ref="markercluster" :styles="clusterStyles" @click="clusterClick" :averageCenter="true" :zoomOnClick="true" :ignoreHidden="true" @mouseout="outCluster" @mouseover="inCluster">
        <GmapMarker
          :key="'a'+index"
          :ref="'a'+index"
          :visible="showAddress && $store.state.dashboardMarkers == 'general' && showClusters"
          v-for="(m, index) in address"
          :position="{lat: m.lat, lng: m.lng}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/home_marker.png'), scaledSize: { width: 45, height: 47 }, data: m, type: 2}"
          @click="openAddressInfo(m.user_id)"
        >
        <infoMarker v-if="$store.state.dashboardMarkers == 'general' && m.user_id == addressMarkerInfo" :user_id="m.user_id" :closeAddressInfo="closeAddressInfo" type="multipleDevices" markerType="address"/>
        </GmapMarker>
        <GmapMarker
					v-for="(m, index) in personalesInCluster"
					:key="'w'+index"
          :ref="'w'+index"
          :visible="showPersonales && !checkIfMonitorInAlert(m.deviceID) && $store.state.dashboardMarkers == 'general' && showClusters"
          :position="{lat: m.latitud, lng: m.longitud}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png'), scaledSize: { width: 32, height: 40 }, data: m, type: 1}"
          @click="requestUserInfo(m)"
        >
        </GmapMarker>
        <GmapMarker
				v-for="(m, index) in fixedDevices"
				:key="'z'+index"
				:ref="'z'+index"
				:visible="showFixedDevices && $store.state.dashboardMarkers == 'general' && showClusters"
				:position="{lat: +m.location.lat, lng: +m.location.lng}"
				:clickable="true"
				customInfo="Work"
				:icon="{url: require('../assets/imgicons/fixedMarker.png'), scaledSize: { width: 60, height: 60 }, data: m, type: 3}"
				@click="openFixedDeviceInfo(m.device_id)"
			>
				<infoMarker
          v-if="$store.state.dashboardMarkers == 'general' && m.device_id == fixedDeviceId"
					:closeInfo="closeContentInfo"
					:userInfo="m"
          :user_id="m.user_id"
          :closeAddressInfo="closeFixedDeviceInfo"
          type="oneDevice"
          markerType="fixedDevice"
          :device_id="m.device_id"
				/>
			</GmapMarker>

<!--       cameras -->
        <GmapMarker
          :key="'c'+index"
          :ref="'c'+index"
          :visible="$store.state.dashboardMarkers == 'cameras' && showClusters && m.shared_panel  && (!showCameras || showCameras && Boolean(m.online))"
          v-for="(m, index) in cameras"
					:title="JSON.stringify(m)"
          :position="m.position"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require(m.online ? '../assets/imgicons/ICONOS SOSRecurso 1@2x.png' : '../assets/imgicons/ICONOS SOSRecurso 10-2@2x.png'), scaledSize: { width: 32, height: 40 }, data: m, type: 3}"
          @click="startCameraView(m)"
        />

      <GmapMarker
          :key="'t'+index"
          :ref="'t'+index"
          :visible="$store.state.dashboardMarkers == 'gps' && showClusters && showButtons && !checkIfButtonInAlert(m.deviceID)"
          v-for="(m, index) in botonesSOS"
					:title="JSON.stringify(m)"
          :position="{lat: m.lat, lng: m.lng}"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require('../assets/imgicons/button_marker.png'), scaledSize: { width: 48, height: 50 }, data: m, type: 3}"
				@click="openButtonInfo(m.deviceID)"
			>
				<ButtonInfo
          v-if="$store.state.dashboardMarkers == 'gps' && m.deviceID == ButtonId"
					:closeInfo="closeButtonInfo"
          :device="m"
				/>
        </GmapMarker>

        <!--      alertas botones -->
        <GmapMarker
          :key="'asd'+index"
          :ref="'asd'+index"
          :visible="showClusters && showButtonAlerts"
          :animation="1"
          v-for="(m, index) in alertButtons"
					:title="JSON.stringify(m)"
          :position="{lat: +m.geo_usuario.lat, lng: +m.geo_usuario.lng}"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png'), scaledSize: { width: 32, height: 40 }}"
          @click="showModalAlert(m)"
          style="z-index: 500;"
			>
				<ButtonInfo
          v-if="m.button_id == alertButtonId"
					:closeInfo="closeAlertButtonInfo"
          :device="m.buttonData"
				/>
        </GmapMarker>


<!--         <TrackingMarkers /> -->
      </GmapCluster>

<!--         SIN CLUSTER -->
        <GmapMarker
          :key="'s'+index"
          :ref="'s'+index"
          :visible="showAddress && $store.state.dashboardMarkers == 'general' && !showClusters"
          v-for="(m, index) in address"
          :position="{lat: m.lat, lng: m.lng}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/home_marker.png'), scaledSize: { width: 45, height: 47 }, data: m, type: 2}"
          @click="openAddressInfo(m.user_id)"
        >
        <infoMarker v-if="$store.state.dashboardMarkers == 'general' && m.user_id == addressMarkerInfo" :user_id="m.user_id" :closeAddressInfo="closeAddressInfo" type="multipleDevices" markerType="address"/>
        </GmapMarker>
        <GmapMarker
					v-for="(m, index) in personalesInCluster"
					:key="'r'+index"
          :ref="'r'+index"
          :visible="showPersonales && !checkIfMonitorInAlert(m.deviceID) && $store.state.dashboardMarkers == 'general' && !showClusters"
          :position="{lat: m.latitud, lng: m.longitud}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png'), scaledSize: { width: 32, height: 40 }, data: m, type: 1}"
          @click="requestUserInfo(m)"
        >
        </GmapMarker>
              
<!--         cámaras -->
        <GmapMarker
          :key="'u'+index"
          :ref="'u'+index"
          :visible="$store.state.dashboardMarkers == 'cameras' && !showClusters && m.shared_panel && (!showCameras || showCameras && Boolean(m.online))"
          v-for="(m, index) in cameras"
					:title="JSON.stringify(m)"
          :position="m.position"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require(m.online ? '../assets/imgicons/ICONOS SOSRecurso 1@2x.png' : '../assets/imgicons/ICONOS SOSRecurso 10-2@2x.png'), scaledSize: { width: 32, height: 40 }, data: m, type: 3}"
          @click="startCameraView(m)"
        />

<!--         botones -->
        <GmapMarker
          :key="'y'+index"
          :ref="'y'+index"
          :visible="$store.state.dashboardMarkers == 'gps' && !showClusters && showButtons && !checkIfButtonInAlert(m.deviceID)"
          v-for="(m, index) in botonesSOS"
					:title="JSON.stringify(m)"
          :position="{lat: m.lat, lng: m.lng}"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require('../assets/imgicons/button_marker.png'), scaledSize: { width: 48, height: 50 }, data: m, type: 3}"
				@click="openButtonInfo(m.deviceID)"
			>
				<ButtonInfo
          v-if="$store.state.dashboardMarkers == 'gps' && m.deviceID == ButtonId"
					:closeInfo="closeButtonInfo"
          :device="m"
				/>
        </GmapMarker>
<!--      alertas botones -->
        <GmapMarker
          :key="'dsa'+index"
          :ref="'dsa'+index"
          :visible="!showClusters && showButtonAlerts"
          :animation="1"
          v-for="(m, index) in alertButtons"
					:title="JSON.stringify(m)"
          :position="{lat: +m.geo_usuario.lat, lng: +m.geo_usuario.lng}"
          :clickable="true"
          customInfo="Work"
					testinfo="prueba"
          :icon="{url: require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png'), scaledSize: { width: 36, height: 48 }}"
				@click="showModalAlert(m)"
			>
				<ButtonInfo
          v-if="m.button_id == alertButtonId"
					:closeInfo="closeAlertButtonInfo"
          :device="m.buttonData"
				/>
        </GmapMarker>


      <MapSoloTracking />
      <GmapPolygon
        v-if="path.length > 0 && polygonReady && $store.state.dashboardMarkers !== 'tracking'"
        :path="path"
        ref="polyline"
        :options="{
          strokeColor: '#d0282e',
          strokeOpacity: 0.4,
          fillColor: hoverComuna ? 'gray' : 'white',
          fillOpacity: 0.2
        }"
        @mouseover="hoverComuna = true"
        @mouseout="hoverComuna = false"
        >
      </GmapPolygon>
    </GmapMap>

    <div style="position: absolute; top: 30px; left: 30px; z-index: 99">
      <CButton
        v-c-tooltip="'Acercar Mapa'"
        class="mx-1"
        color="success"
        @click="changeZoom()"
      >
        <font-awesome-icon icon="plus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Alejar Mapa'"
        class="mx-1"
        color="primary"
        @click="changeZoom(1)"
      >
        <font-awesome-icon icon="minus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Ir a mi cuadrante'"
        color="success"
        class="mx-1"
        @click="fitCuadrante()"
      >
        <CImg
          class="img white-color-image-overlay"
          width="30"
          :src="require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')"
        />
      </CButton>
      <CButton
        v-c-tooltip="'Ver mapa calor alertas'"
        :color="showHeatMap ? 'success':'primary'"
        class="mx-1"
        @click="toggleHeatMap()"
      >
				<CSpinner
					style="width:20px;height:20px;"
					color="white"
					v-if="loadingHeatMap"
				/>
        <font-awesome-icon v-else icon='fire-alt' style="font-size: 20px;"/>
      </CButton>
    </div>

<!--     map filters -->
    <div class="dashboard-map-controllers" :class="mapControllersOpen ? 'opened':'closed'">
      <!-- cameras -->
      <div class="tooltip1">
      <span class="tooltiptext1 pl-2">Cámaras en mapa</span>
        <CButton
          v-if="$store.state.dashboardMarkers == 'cameras'"
          class="mx-2 p-0"
          @click="toogleCameras()"
        >
        <CImg
          class="img"
          width="46"
          :src="showCameras ? require('../assets/imgicons/ICONOS SOSRecurso 1@2x.png') :  require('../assets/imgicons/ICONOS SOSRecurso 10-2@2x.png')"
        />
        </CButton>
      </div>
      <!-- alerts -->
      <div class="tooltip1">
      <span class="tooltiptext1">Alertas en mapa</span>
        <CButton
          class="mx-2 p-0"
          @click="toogleAlertas()"
        >
          <CImg
            class="img"
            width="46"
            :src="showAlertas ? require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png') :  require('../assets/imgicons/ICONOS SOSRecurso 7-2@2x.png')"
          />
      </CButton>
      </div>
      <!-- address --> 
      <div class="tooltip1">
      <span class="tooltiptext1">Dispositivos tipo monitor en mapa</span>
      <CButton
        v-if="$store.state.dashboardMarkers == 'general'"
        class="mx-2 p-0"
        @click="toggleAddress()"
        :style="[showAddress ? { opacity: '1' } : { opacity: '.7' }]"
      >
        <CImg
          class="img"
          width="60"
          :src="require('../assets/imgicons/home_marker.png')"

        />
      </CButton>
      </div>
      <!-- devices -->
      <div class="tooltip1">
      <span class="tooltiptext1">Dispositivos personales en mapa</span>
      <CButton
        v-if="$store.state.dashboardMarkers == 'general'"
        class="mx-2 p-0"
        @click="tooglePersonales()"
      >
        <CImg
          class="img"
          width="46"
          :src="showPersonales ? require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png') :  require('../assets/imgicons/ICONOS SOSRecurso 9-2@2x.png')"
        />
      </CButton>
      </div>
      <!-- fixed devices -->
      <div class="tooltip1">
      <span class="tooltiptext1">Dispositivos Fijos en mapa</span>
      <CButton
      v-if="$store.state.dashboardMarkers == 'general'"
      :style="[showFixedDevices ? { opacity: '1' } : { opacity: '.7' }]"
        class="mx-2 p-0"
        @click="toogleFixedDevices()"
      >
        <CImg
          class="img"
          width="80"
          height="80"
          :src="require('../assets/imgicons/fixedMarker.png')"
        />
      </CButton>
      </div>
      <!-- botones -->
      <div class="tooltip1">
      <span class="tooltiptext1">Botones SOS</span>
      <CButton
      v-if="$store.state.dashboardMarkers == 'gps'"
      :style="[showButtons ? { opacity: '1' } : { opacity: '.7' }]"
        class="mx-2 p-0"
        @click="showButtons = !showButtons"
      >
        <CImg
          class="img"
          width="60"
          height="60"
          :src="require('../assets/imgicons/button_marker.png')"
        />
      </CButton>
      </div>
      <!-- Alertas botones -->
      <div class="tooltip1">
      <span class="tooltiptext1">Alertas Botones SOS</span>
      <CButton
      :style="[showButtonAlerts ? { opacity: '1' } : { opacity: '.7' }]"
        class="mx-2 p-0"
        @click="showButtonAlerts = !showButtonAlerts"
      >
        <CImg
          class="img"
          width="46"
          :src="require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png')"
        />
      </CButton>
      </div>
      <!-- cluster -->
      <div class="tooltip1">
      <span class="tooltiptext1">Marcadores agrupados</span>
      <CButton
      :style="[showClusters ? { opacity: '1' } : { opacity: '.7' }]"
        class="mx-2 p-0"
        @click="showClusters = !showClusters"
      >
        <CImg
          :style="showClusters ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="40"
          height="40"
          :src="require('../assets/imgs/cluster40.png')"
        />
      </CButton>
      </div>

      <button type="button" class="btn btn-pill btn-primary custom-btn-map-controllers"
        @click="mapControllersOpen = !mapControllersOpen">
        <font-awesome-icon :icon="mapControllersOpen ? 'chevron-left':'chevron-right'"/>
      </button>
    </div>
    <modalBoton :showModal="showModalButton" :buttonData="modalButtonData" :closeModal="closeModalButton"/>
<!-- 		<ModalCameraViewer v-if="showModalCameraViewer" :showModal="showModalCameraViewer" :cameraInfo="viewCameraInfo" :closeModal="closeModalCameraViewer"/> -->
		<!-- <CentralPreviewVideoAlert :alerts="alertas"/> -->

  <!-- Filtros Tracking -->
    <CModal
      title="Filtrar Rutas"
      color="primary"
      class="modal-delete"
      :show.sync="$store.state.modalTrackingFilter"
      style="z-index: 9999999999999 !important; position: absolute">
      <div>
        <CRow v-if="tracking_markers == 'record'">
          <CCol col="12" sm="6">
            <div style="display: flex;align-items:center;">
            <label style="margin-bottom:0; font-weight: 600;">Desde: </label>
              <datetime
                width="100%"
                class="my-2 mx-4 datepickers__datepicker"
                type="date"
                v-model="filterTrackingMarkers.date_start"
                :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
              ></datetime>
          </div>
          </CCol>
          <CCol col="12" sm="6">
            <div style="display: flex;align-items:center;">
            <label style="margin-bottom:0; font-weight: 600;">Desde: </label>
              <datetime
                width="100%"
                class="my-2 mx-4 datepickers__datepicker"
                type="date"
                v-model="filterTrackingMarkers.date_end"
                :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
              ></datetime>
          </div>
          </CCol>
          <CCol col="12" sm="6">
            <div style="display: flex;align-items:center;">
            <label style="margin-bottom:0; font-weight: 600;">Cantidad: </label>
              <CInput v-model="filterTrackingMarkers.limit" style="margin-bottom: 0; margin-left: 5px;margin-top: 5px;" type="number"></CInput>
          </div>
          </CCol>

        </CRow>
          <SearchUsersInput :handlerSelectedUsers="handlerSelectedUsers" />
      </div>
          <div slot="footer" style="display: flex; margin-left: auto;margin-right: 15px;">
            <CButton @click="tracking_markers == 'real_time' ? getTracking('restore') : restoreRecordTracking()" color="primary" class="mr-3">
              Reestablecer
            </CButton>
            <CButton @click="getTracking" color="primary">
              Filtrar
            </CButton>
          </div>
    </CModal>
    <CModal
      title="Borrar Datos De Tracking en Tiempo Real"
      color="primary"
      class="modal-delete"
      :show.sync="$store.state.modalDeleteAllRtTracking"
      style="z-index: 9999999999999 !important; position: absolute">
      <div>
        <span>¿Borrar Datos De Tracking en Tiempo Real de Forma Definitiva?</span>
      </div>
          <div slot="footer" style="display: flex; margin-left: auto;margin-right: 15px;">
            <CButton @click="deleteAllRtTracking" color="primary" class="mr-3">
              Borrar
            </CButton>
            <CButton color="secondary" @click="$store.state.modalDeleteAllRtTracking = false">
              Cancelar
            </CButton>
          </div>
    </CModal>

<!--     Cámara no inactiva -->
    <CModal
      title="Cámara Inactiva"
      color="primary"
      class="modal-delete"
      :show.sync="unavailableCamera"
      style="z-index: 9999999999999 !important; position: absolute">
      <div>
        <span>La cámara seleccionada no está en línea</span>
      </div>
    </CModal>

    			<!--     Dispositivo no disponible -->
			<CModal
      title="Dispositivo no disponible"
      color="primary"
      class="modal-delete"
      :show.sync="$store.state.unavailableDevice"
      style="z-index: 9999999999999 !important; position: absolute">
      <div>
        <span>El dispositivo no está disponible</span>
      </div>
    </CModal>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import modalBoton from './widgets/modalBoton'
import { cameraList, getCamerasGeo, getTuyaCamera, getTuyaCameraVideoInfo } from '../api/cameras'
import {alertHeatmap} from '@/api/alerts'
import TrackingMarkers from './widgets/Tracking/TrackingMarkers'
import MapSoloTracking from './widgets/Tracking/MapSoloTracking'
import ContentInfo from './widgets/InfoWindow/ContentInfo'
import ButtonInfo from './widgets/InfoWindow/ButtonInfo'
import GmapHeatmap from './widgets/Map/Heatmap'
import {gmapApi} from 'vue2-google-maps';
import { mapGetters } from "vuex"
import {createVideoCallRoom,initVideoCallWithDevice} from '../api/atenciones'
import ModalCameraViewer from './widgets/TuyaCamerasView/ModalCameraViewer'
import CentralPreviewVideoAlert from './widgets/CentralPreviewVideoAlert'
import VideoCallRoomMultiple from '@/views/videocall/VideoCallRoomMultiple'
import { getUsersGeo, AlertTest } from '@/api/users.js'
import { getFixedPanelDevices } from '@/api/devices.js'
import {   getCompanyTrackingByDate } from '@/api/tracking.js'

import moment from 'moment'
import  infoMarker  from './widgets/InfoWindow/InfoMarker'
import { onValue, ref} from "firebase/database";
import SearchUsersInput from './widgets/SearchUsersInput'

export default {
  name: 'Dashboard',
  beforeCreate: function() {
      document.body.className = 'home';
  },
  beforeDestroy: function() {
      document.body.classList.remove("home")
  },
  components: {modalBoton, TrackingMarkers, ContentInfo, MapSoloTracking, ModalCameraViewer, GmapHeatmap, VideoCallRoomMultiple, CentralPreviewVideoAlert, infoMarker, SearchUsersInput, ButtonInfo},
  computed: {
    ...mapGetters(["userData"]),
		google: gmapApi,
		heatData() {
			return this.google && this.heatMapMarkers.map((marker) => {
				return {
					location: new this.google.maps.LatLng(marker), 
					weight: 2,
				}
			});
		},
		personalesFromCluster() {
			return this.personales.filter((personal) => {
				return this.usersInfo.includes(personal.deviceID) && this.infoStatus.includes(personal.deviceID)
			})
		},
		personalesInCluster() {
			return this.personales.filter((personal) => {
				return !this.usersInfo.includes(personal.deviceID) || !this.infoStatus.includes(personal.deviceID)
			})
		},
  },
  data () {
    return {
      cameras: [],
      polylineOptions: {
        strokeWeight: 7,
        strokeColor: 'green',
        strokeOpacity: 0.7,
      },
      mapControllersOpen: true,
      infoOpen: false,
      infoPos: {
        lat: -33.4373063,
        lng: -70.634297
      },
      containerHeight: 700,
      centerMap: {
        lat: -33.4373063,
        lng: -70.634297
      },
      mapZoom: 12,
      path: [],
      drawingPath: [],
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true
      },
      clusterMarkers: [],
      alertas: [],
      address: [],
      allDevices: [],
      monitores: [],
      personales: [],
      camarasIP: [],
      botonesSOS: [],
      alertButtons: [],
      monitorear: null,
      showAlertas: true,
      showAddress: true,
      showPersonales: true,
      showFixedDevices: true,
      showCameras: false,
      showButtons: true,
      showButtonAlerts: true,
      showClusters: false,
      showModalButton: false,
      modalButtonData: null,
      showEndAlert: false,
      alertData: null,
      animated: false,
      hoverComuna: false,
      admin: null,
      cuadrante: 0,
      polygonReady: false,
      clusterStyles: [
        {
          textColor: 'black',
          textSize: 12,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        },
       {
          textColor: 'black',
          textSize: 12,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        },
       {
          textColor: 'black',
          textSize: 12,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        }
      ],
      onCluster: false,
      usersInfo: [],
      infoStatus: [],
			viewCameraInfo: false,
			showModalCameraViewer: false,
			heatMapMarkers: [],
			showHeatMap: false,
			loadingHeatMap: false,
      tracking: [],
      originalRecordTracking: [],
      filterTrackingMarkers: {
        date_start: '2021-01-20',
        date_end: moment().format('YYYY-MM-DD'),
        limit: 1000,
        page: 1
      },
      polylineInfo: null,
      addressMarkerInfo: null,
      trackingMarkerInfo: null,
/*       route: [] */
      fixedDevices: [],
      showFixedDevices: true,
      fixedDeviceId: null,
      ButtonId: null,
      alertButtonId: null,
      rtTracking: [],
      tracking_markers: 'real_time',
      user_id: null,
      rtTrackingOriginal: [],
      listenerTracking: null,
      unavailableCamera: false
    }
  },
  watch:{
    userData: function(data){
      console.log("userData: ", data)
    },
    '$store.state.dashboardMarkers': function(value) {
      if (value == 'general') {
        this.getAddress()
        this.getFixedDevices()
      }
      if (value == 'tracking') {
        this.getTracking()
      }
      if (value == 'cameras') {
        this.getCamerasGeo()
      }
    },
    '$store.state.cuadranteMarkers': function(value) {
      if (this.$store.state.dashboardMarkers == 'general') {
        this.getAddress()
        this.getFixedDevices()
      }
      if (this.$store.state.dashboardMarkers == 'tracking') {
        if (!this.$store.state.trackingType) {
          this.getTracking()
        } else {

          if (isNaN(this.$store.state.cuadranteMarkers)) {
            this.rtTracking = [...this.rtTrackingOriginal]
          } else {
            this.rtTracking = [...this.rtTrackingOriginal].filter(el => el.user.cuadrante_id == this.$store.state.cuadranteMarkers)
          }
        }
      }
      if (this.$store.state.dashboardMarkers == 'cameras') {
        this.getCamerasGeo()
      }
    },
    '$store.state.trackingType': function(value) {
      if (value) {
        this.tracking_markers = 'real_time'
      }
      if (!value) {
        this.getTracking()
        this.tracking_markers = 'record'
      }
    },
    '$store.state.resetRtTracking': function(value) {
      if (value) {
        this.resetTrackingListener()
      }
    },
  },
  async mounted(){
/*     const obj = {user_id: 5}
    AlertTest(obj).then(res => {
      console.log(res);
    }) */

    this.rtTrackingListener()
    this.rtTrackingAddChildListener()
    this.rtTrackingRemovedChildListener()
    this.getFixedDevices()
    this.getAddress()
    this.admin = this.$store.state.userData
    this.cuadrante = this.admin.info.cuadrante_id
    this.containerHeight = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight
    this.childAddedListenner()
    this.childRemovedListenner()
    const data = {limit: 12, page:1, keep_ids: []}
    const camaras = await cameraList(data)
    // this.camarasIP = camaras.cuadrante_cameras
		this.$store.commit('setTuyaCameras', camaras.connections)
    const self = this
    this.$events.listen('filterSegments', eventData => {
      self.filter = eventData
      self.filterMonitores()
    })
    this.$events.listen('centerMap', eventData => {
      self.centerMap = {
        lat: eventData.latitud,
        lng: eventData.longitud
      }
      self.mapZoom = 18
    })
    setTimeout(function(){
      self.setCuadranteBounds(self.cuadrante)
      self.animated = true
			self.mapControllersOpen = false
    }, 1000)

    if (this.$store.state.dashboardMarkers == 'cameras') {
      this.getCamerasGeo()
    };
    if (this.$store.state.dashboardMarkers == 'tracking') {
      this.getTracking()
    };

  },
  methods: {
    deleteAllRtTracking() {
      firebase.database(this.$store.state.trackingDB).ref(`/SOSclick/tracking/${this.$store.state.userData.info.cuadrante_id}`).remove()
      this.$store.commit('setResetRtTracking', true)
      this.$store.commit('setModalDeleteAllRtTracking', false)
    },
    handlerSelectedUsers(selectedUsers)  {
      this.user_id = selectedUsers
    },
    openAddressInfo(id) {
      this.fixedDeviceId = null

      if (this.addressMarkerInfo == id) {
        this.addressMarkerInfo = null;
      } else {
        this.addressMarkerInfo = id;
      }
    },
    closeAddressInfo() {
      this.addressMarkerInfo = null;
    },
    changePolygonMouseOver: function(e,index) {
        const ref = 'polyline' + index;
        this.$refs[ref][0].options = {
          strokeWeight: 10,
          strokeColor: 'green',
          strokeOpacity: 1,
        }
      const startRef = 'markerstart' + index;
      this.$refs[startRef][0].icon = {...this.$refs[startRef][0].icon,
        scaledSize: {
          height: 52,
          width: 35,
        }
      }
      const endRef = 'markerend' + index;
      this.$refs[endRef][0].icon = {...this.$refs[endRef][0].icon,
        scaledSize: {
          height: 52,
          width: 35,
        }
      }
    },
    changePolygonMouseOut: function(index) {
      const ref = 'polyline' + index;
      this.$refs[ref][0].options = {
        strokeWeight: 7,
        strokeColor: 'green',
        strokeOpacity: 0.7,
      }
      const startRef = 'markerstart' + index;
      this.$refs[startRef][0].icon = {...this.$refs[startRef][0].icon,
        scaledSize: {
          height: 40,
          width: 28,
        }
      }
      const endRef = 'markerend' + index;
        this.$refs[endRef][0].icon = {...this.$refs[endRef][0].icon,
          scaledSize: {
            height: 40,
            width: 28,
          }
        }
      },
    openTrackingInfo(id) {
      if (this.trackingMarkerInfo == id) {
        this.trackingMarkerInfo = null;
      } else {
        this.trackingMarkerInfo = id;
      }
    },
    openFixedDeviceInfo(id) {
      this.addressMarkerInfo = null

      if (this.fixedDeviceId == id) {
        this.fixedDeviceId = null;
      } else {
        this.fixedDeviceId = id;
      }
    },
    openButtonInfo(id) {
      if (this.ButtonId == id) {
        this.ButtonId = null;
      } else {
        this.ButtonId = id;
      }
    },
    openAlertButtonInfo(id) {
      if (this.alertButtonId == id) {
        this.alertButtonId = null;
      } else {
        this.alertButtonId = id;
      }
    },
    closeTrackingInfo() {
      this.trackingMarkerInfo = null;
    },
    closeFixedDeviceInfo() {
      this.fixedDeviceId = null;
    },
    closeButtonInfo() {
      this.ButtonId = null;
    },
    closeAlertButtonInfo() {
      this.alertButtonId = null;
    },
    getAddress() {
      const data = {
        limit: 1000,
        page: 1,
        cuadrante_id: isNaN(this.$store.state.cuadranteMarkers) ? null : this.$store.state.cuadranteMarkers
      }
      getUsersGeo(data).then(res => {
        this.address = res.users.map(user => user.address_geocoding)
      })
    },
    getFixedDevices() {
      const data = {cuadrante_id: isNaN(this.$store.state.cuadranteMarkers) ? null : this.$store.state.cuadranteMarkers}

      getFixedPanelDevices(data).then(res => {
        console.log(res);
        this.fixedDevices = res.devices
      })
    },
    getTracking(restore){
      const data = {
        date_start: this.filterTrackingMarkers.date_start,
        date_end: this.filterTrackingMarkers.date_end,
        limit: this.filterTrackingMarkers.limit,
        page: this.filterTrackingMarkers.page,
        user_id: this.user_id,
        cuadrante_id: isNaN(this.$store.state.cuadranteMarkers) ? null : this.$store.state.cuadranteMarkers
      }

    if (!this.$store.state.trackingType) {
      getCompanyTrackingByDate(data).then(res => {
        this.tracking = res.tracking
        this.originalRecordTracking = [res.tracking]
        this.$store.commit('setModalTrackingFilter', false)
      })
      } else if (this.$store.state.trackingType) {
        this.$store.commit('setModalTrackingFilter', false)
        if (restore == 'restore') {
          this.rtTracking = [...this.rtTrackingOriginal]
          return
        } else {
          this.rtTracking = this.rtTrackingOriginal.filter(track => {
            return this.user_id.includes(track?.user?.user_id) && moment(track?.position?.date).isBetween(moment(this.filterTrackingMarkers.date_start), moment(this.filterTrackingMarkers.date_end));
          })
        }
      }
    },
    restoreRecordTracking() {
      const data = {
        date_start: '2021-01-20',
        date_end: moment().format('YYYY-MM-DD'),
        limit: 1000,
        page: 1
      }
      getCompanyTrackingByDate(data).then(res => {
        this.tracking = res.tracking
        this.originalRecordTracking = [res.tracking]
        this.$store.commit('setModalTrackingFilter', false)
      })
    },
    getAdminPosition(){
      if(this.admin.id <= 3){
        const options = {
          enableHighAccuracy: true,
          timeout: 10000,
        }
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const center = {
              center: {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
              },
              zoom: 12
            }
            this.$store.commit('setMapCenter', center)
          },
          () => this.fitCuadrante(),
          options
        )
      }
      else{
        this.fitCuadrante()
      }
    },
    getCamerasGeo() {
      const data = {cuadrante_id: isNaN(this.$store.state.cuadranteMarkers) ? null : this.$store.state.cuadranteMarkers}
      getCamerasGeo(data).then(res => {
        console.log(res);
        this.cameras = res.cameras
      })
    },
		async toggleHeatMap(){
			this.showHeatMap = !this.showHeatMap
			if(this.showHeatMap){
				this.loadingHeatMap = true
				const heatMapMarkers = await alertHeatmap()
				this.heatMapMarkers = heatMapMarkers.data
				this.loadingHeatMap = false
			}
			else{
				this.heatMapMarkers = []
			}
		},
		closeModalCameraViewer(){
				this.showModalCameraViewer = false
				this.viewCameraInfo = null
		},
		async startCameraView(camera){
      if (camera.shared_panel) {
        const cuadrante_id = camera.user.info.cuadrante_id
        const keep_id = camera.id
        
        getTuyaCamera({ camera_id: camera.camera_id }).then(res => {
          this.$router.push(`/monitors?camera_id=${camera.camera_id}&cuadrante_id=${cuadrante_id}&keep_id=${keep_id}`)
      }).catch(e => {
        if (e.response.data.code == 404) {
          this.unavailableCamera = true
        }
      })
    }
/* 			this.viewCameraInfo = camera
			this.showModalCameraViewer = true */
/*       this.router.push(`/monitors/${}`) */
		},
    requestUserInfo(m){
      const found = this.usersInfo.findIndex((item) => {
        return item === m.deviceID
      })
      if(found === -1){
        this.usersInfo.push(m.deviceID)
      }

      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === m.deviceID
      })
      if(foundStatus === -1){
        this.infoStatus.push(m.deviceID)
      }
      else{
        this.infoStatus.splice(foundStatus, 1)
      }
    },
    closeContentInfo(m){
      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === m.deviceID
      })
      if(foundStatus !== -1){
        this.infoStatus.splice(foundStatus, 1)
      }
    },
    botonClick(m){
      console.log(m)
      this.showModalButton = true
      this.modalButtonData = m
    },
    closeModalButton(){
      this.showModalButton = false
      this.modalButtonData = null
    },
    inCluster(){
      this.onCluster = true
    },
    outCluster(){
      this.onCluster = false
      const self = this
      setTimeout(function() {
        if(!self.onCluster){
          self.clusterMarkers = []
        }
      }, 3000);
    },
    returnIcon(marker){
      if(marker.camera_id){
        return require('../assets/imgicons/ICONOS SOSRecurso 1-2@2x.png')
      }
      else if(marker.data){
        return require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png')
      }
      else if(marker.deviceData && marker.deviceData.is_monitor){
        return require('../assets/imgicons/ICONOS SOSRecurso 4@2x.png')
      }
      else{
        return require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png')
      }
    },
    markerAction(marker){
			if(marker.type == 1 | marker.type == 2){
				this.requestUserInfo(marker.data.device_id)
			}
        else if(marker.type == 3){
          this.openFixedDeviceInfo(marker.data.device_id)
        }
      else if(marker.camera_id){
				this.startCameraView(marker)
      }
      else if(marker.data){
        this.showModalAlert(marker)
      }
      else{
        this.setMonitorear(marker)
      }
      this.clusterMarkers = []
    },
    changeZoom(type = 0){
      this.$refs.map.$mapPromise.then((map) => {
        const actual = map.getZoom()
        type === 0 ? map.setZoom(actual + 1) : map.setZoom(actual - 1)
      })
    },
    clusterClick(cluster){
      const mapZoom = cluster.map_.getZoom()
      if(mapZoom === 22){
        const self = this
        if(self.clusterMarkers.length > 0){
          self.clusterMarkers = []
        }
        else{
          let radius = 1
          const markers = cluster.getMarkers()
          const initialPos = markers[0].getPosition()
          markers.map(function(item, index){
            let degrees = 180 + ( index * (360 / (8*radius)) )
            let marcador = item.getTitle() ? JSON.parse( item.getTitle() ):{}
            marcador.position = {
              lat: initialPos.lat() - 0.0000065 - ( Math.cos( degrees * (Math.PI/180) ) * 0.00002 * radius ),
              lng: initialPos.lng() - ( Math.sin( degrees * (Math.PI/180) ) * 0.000025 * radius )
            }
						marcador.icon = item.icon.url
						marcador.data = item.icon.data
						marcador.type = item.icon.type
            self.clusterMarkers.push(marcador)
            if(index > 0 && ((index + 1)%(8*radius*Math.pow(2,radius-1)) === 0)){
              radius++
            }
          })
        }
      }
    },
    fitCuadrante(){
      let bounds = new google.maps.LatLngBounds();
      this.path.forEach(paths => {
        paths.forEach(pathItem => {
          bounds.extend(new google.maps.LatLng(pathItem.lat, pathItem.lng))
        })
      })
      this.$refs.map.fitBounds(bounds, 0)
    },
    setCuadranteBounds(id){
      const self = this
      let bounds = new google.maps.LatLngBounds();
      this.$store.state.userData.cuadrante_bounds.map(function(item){
        self.path.push(item.bounds)
        item.bounds.forEach(path => {
          bounds.extend(new google.maps.LatLng(path.lat, path.lng))
        })
      })
      this.getAdminPosition()
      this.polygonReady = true
    },
    filterMonitores(){
      const self = this
      this.monitores = this.allDevices.filter(function(item){
        if(self.filter.mujeres && self.filter.comercio && self.filter.hogar && item.deviceData && item.deviceData.is_monitor){
          return true
        }
        return (item.deviceData && item.deviceData.is_monitor) && ( ( self.filter.mujeres && item.cluster && item.cluster.includes(1) ) || ( self.filter.comercio && item.cluster && item.cluster.includes(2) ) || ( self.filter.hogar && item.cluster && item.cluster.includes(3) ) )
      })
      this.personales = this.allDevices.filter(function(item){
        if(self.filter.mujeres && self.filter.comercio && self.filter.hogar && !(item.deviceData && item.deviceData.is_monitor)){
          return true
        }
        return !(item.deviceData && item.deviceData.is_monitor) && ( ( self.filter.mujeres && item.cluster && item.cluster.includes(1) ) || ( self.filter.comercio && item.cluster && item.cluster.includes(2) ) || ( self.filter.hogar && item.cluster && item.cluster.includes(3) ) )
      })
    },
    toggleAddress(){
      this.showAddress = !this.showAddress
    },
    toogleCameras(){
      this.showCameras = !this.showCameras
    },
    toogleAlertas(){
      this.showAlertas = !this.showAlertas
    },
    tooglePersonales(){
      this.showPersonales = !this.showPersonales
    },
    toogleFixedDevices(){
      this.showFixedDevices = !this.showFixedDevices
    },
    toogleBotones(){
      this.showBotones = !this.showBotones
    },
    // startMonitorFromAlert(data){
    //   firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + data.deviceID)
    //     .once('value', (snapshot) => {
    //       let datos = snapshot.val()
    //       datos.alerta = data
    //       datos.showPIP = false
    //       datos.startRecord = true
    //       this.$events.fire('startMonitoreo', datos)
    //     })
    // },
    async startMonitorFromAlert(data){
      this.$store.commit("setVideoCallData", {
        status: true,
        alertID: data.alert_id,
        geoUserAlert: data.geo_usuario,
        fullView: true,
        deviceID: data.deviceID,
        calledUserID: data.id_usuario,
        socketUrl: data.videocall.socket_url,
        socketRoom: data.videocall.socket_room,
        videocallID: data.videocall.videocall_id,
        hideVideo: false,
        silent: true
      })
    },
    setMonitorear(data){
      data.showVideo = false
      this.$events.fire('startMonitoreo', data)
    },
    resetMonitor(monitor){
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
    },
    checkIfMonitorInAlert(deviceID){
      const found = this.alertas.findIndex(function(item, index){
        return item.data.deviceID === deviceID
      })
      return found === -1 ? false : true 
    },
    checkIfButtonInAlert(alertButtonId){
      if (!this.showButtonAlerts) {
        return false
      }
      const found = this.alertButtons.findIndex(function(item, index){
        return item.button_id === alertButtonId
      })
      return found === -1 ? false : true 
    },
    childAddedListenner(){
      const self = globalThis
        const { userData } = this.$store.state;
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/Alertas/${userData?.info?.user_id}`).on("child_added", (snapshot) => {
        
        let alert = snapshot.val()    
            this.addAlert(alert);

      })
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/button/${userData?.info?.user_id}`).on("child_added", (snapshot) => {
/*       firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/Buttons/1`).on("child_added", (snapshot) => { */
        let boton = snapshot.val()
/*         if(boton.latitude && boton.longitude){ */
          this.botonesSOS.push(boton)
/*         }
        else{
          if(boton.deviceID){
            firebase.database(this.$store.state.primaryDB).ref('/SOSclick/Buttons/1/' + boton.deviceID).update({command: '[3G*' + boton.deviceID + '*0002*CR]'})
          }
        } */
      })

      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/ButtonAlertas/${userData?.info?.user_id}`).on("child_added", (snapshot) => {
/*       firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/ButtonAlertas/40078`).on("child_added", (snapshot) => { */
          let alertBtn = snapshot.val()
          this.alertButtons.push(alertBtn)
      })




/*       firebase.database(this.$store.state.primaryDB).ref('/SOSclick/Buttons/' + this.cuadrante).on("child_changed", (snapshot) => {
        let boton = snapshot.val()
        if(boton.latitude && boton.longitude){
          const found = this.botonesSOS.findIndex(function(item, index){
            return item.deviceID === boton.deviceID
          })
          if(found !== -1){
            this.botonesSOS[found] = boton
            this.botonesSOS.sort()
          }
          else{
            this.botonesSOS.push(boton)
          }
        }
      }) */
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante).on('child_added',(data) => {
        let monitor = data.val()
        monitor.key = data.key
        if(monitor.latitud && monitor.longitud){
          this.allDevices.push(monitor)
          if(monitor.deviceData && monitor.deviceData.is_monitor){
            this.monitores.push(monitor)
          }
          else{
            this.personales.push(monitor)
          }
        }
      })
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante).on('child_changed',(data) => {
        const datos = data.val()
        if(datos.latitud && datos.longitud){
          const found = this.monitores.findIndex(function(item, index){
            return item.deviceID === datos.deviceID
          })
          if(found !== -1){
            this.monitores[found] = datos
            this.monitores.sort()
          }
          const foundP = this.personales.findIndex(function(item, index){
            return item.deviceID === datos.deviceID
          })
          if(foundP !== -1){
            this.personales[foundP] = datos
            this.personales.sort()
          }
          if(found === -1 && foundP === -1){
            this.allDevices.push(datos)
            if(datos.deviceData && datos.deviceData.is_monitor){
              this.monitores.push(datos)
            }
            else{
              this.personales.push(datos)
            }
          }
        }
      })
    },
    childRemovedListenner(){
      const self = this
        const { userData } = this.$store.state;

      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/Alertas/${userData?.info?.user_id}`).on("child_removed", (data) => {
        
        this.alertas = this.alertas.filter(function(item){
          return item.data.alert_id != data.key
        })
      	this.$store.commit('setAlertsData', this.alertas)
      })
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/button/${userData?.info?.user_id}`).on("child_removed", (data) => {
        this.botonesSOS = this.botonesSOS.filter(function(item){
          return item.deviceID != data.key
        })
      })
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/ButtonAlertas/${userData?.info?.user_id}`).on("child_removed", (data) => {
        this.alertButtons = this.alertButtons.filter(function(item){
          return item.alert_id != data.key
        })
      })
      firebase.database(this.$store.state.primaryDB).ref('SOSclick').child('MonitoreoOjo/' + this.cuadrante).on('child_removed',(data) => {
        const removed = data.val()
        this.allDevices = this.allDevices.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        this.monitores = this.monitores.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        this.personales = this.personales.filter(function(item,index){
          return item.deviceID !== data.key
        })
      })
    },
    addAlert(alert){
      const alertMarker = {
        position:{
          lat: alert.geo_usuario.lat,
          lng: alert.geo_usuario.lng
        },
        data: alert
      }
      const center = {
        center: {
          lat: alert.geo_usuario.lat,
          lng: alert.geo_usuario.lng
        },
        zoom: 18
      }
      this.$store.commit('setMapCenter', center)
      this.alertas.push(alertMarker)
      this.$store.commit('setAlertsData', this.alertas)
    },
    showModalAlert(alerta){
      console.log(alerta)
      this.$events.fire('showAlert', alerta.data ? alerta.data : alerta)
    },
    rtTrackingListener(){
      this.listenerTracking = firebase.database(this.$store.state.trackingDB).ref(`/SOSclick/tracking/${this.$store.state.userData.info.cuadrante_id}`).on("value", (snapshot) => {
        const tracking = snapshot.val()

        const keys = Object.keys(tracking);
        const arr = [];
        for (const key of keys) {
          arr.push(tracking[key]);
        }
        for (const objeto of arr) {
          for (const propiedad in objeto) {
            if (typeof objeto[propiedad] === "object") {
              this.rtTrackingOriginal.push(objeto[propiedad]);
              this.rtTracking.push(objeto[propiedad]);
            }
          }
        }
      })
    },
    resetTrackingListener() {
      if (this.listenerTracking) {
        firebase.database(this.$store.state.trackingDB).ref(`/SOSclick/tracking/${this.$store.state.userData.info.cuadrante_id}`).off(this.listenerTracking)
        this.rtTracking = []
        this.rtTrackingListener()
      }
    },
    rtTrackingAddChildListener() {
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/tracking/${this.$store.state.userData.info.cuadrante_id}`).on("child_added", (snapshot) => {
        console.log('child_added', snapshot);
        let tracking = snapshot.val()
        this.rtTracking.push(tracking)
      })
    },
    rtTrackingRemovedChildListener() {
      firebase.database(this.$store.state.primaryDB).ref(`/SOSclick/tracking/${this.$store.state.userData.info.cuadrante_id}`).on("child_removed", (data) => {
        console.log('child_removed',data);
        this.rtTracking = this.rtTracking.filter(function(item){
          return item.data.trackingID != data.trackingID
        })
      })
    },
  }
}
</script>

<style>
.home .c-main, .home .c-main > div{padding: 0!important;}
.map-container {
  width: 100%;
  height: 700px;
}
.dropclusters{
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.img.gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.tooltipContainer {
  position: relative;
  display: inline-block;
  z-index: 12312;
}

.tooltipContainer .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 12312;
  bottom: 50%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 25px;
}

.tooltipContainer .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: #313131;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip1 .tooltiptext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
  opacity: 1;
}
</style>